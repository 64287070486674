body {
  margin: 0;
  font-family: 'Poppins', -apple-systrem, BlinkMacSystremFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
	font-family: 'Lazy Dog';
	src: url('../public/fonts/lazy_dog.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@import url('./stylesheets/styles.css');
